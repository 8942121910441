import * as _ from "lodash-es";
import type {
    GetStaticPaths,
    GetStaticPropsContext,
    InferGetStaticPropsType,
    NextPage,
    PreviewData,
} from "next";
import { ParsedUrlQuery } from "querystring";

import type {
    StrapiContactFormPage,
    StrapiGeneralTextPage,
    StrapiLegacyPreFooter,
    StrapiProductFeaturePage,
} from "@/types/strapi";

import { ContactFormPageTemplate } from "@/ui/templates/contact_form_page";
import { GeneralTextPageTemplate } from "@/ui/templates/general_text_page";
import { HomePageTemplate } from "@/ui/templates/home_page";
import { LegacyProductPageTemplate } from "@/ui/templates/legacy_product_page";

import { findSlugCollectionByPageSlug } from "@/util/cms_util";
import { generatePageLevelProps } from "@/util/data_util";
import { getHubSpotForm } from "@/util/form_util";
import {
    generateLegacyPreFooterData,
    generatePreFooterData,
    getProductFeatureBackgroundMedia,
} from "@/util/strapi_data_util";
import {
    getDynamicPageSlugs,
    getGlobals,
    getHomePageExperiment,
    getProductSlugPage,
    getSlugPageById,
} from "@/util/strapi_util";

export const getStaticProps = async ({
    params,
    preview: previewMode,
}: GetStaticPropsContext<ParsedUrlQuery, PreviewData>) => {
    const slug = params?.slug;

    if (typeof slug !== "string") {
        return { notFound: true };
    }

    const collection = await findSlugCollectionByPageSlug(slug);

    if (!collection) {
        return { notFound: true };
    }

    const globalsData = await getGlobals(previewMode);

    switch (collection.contentType) {
        case "api::exp-home.exp-home": {
            const pageData = await getHomePageExperiment(slug, previewMode);
            return {
                props: {
                    ...generatePageLevelProps({
                        path: `/${slug}`,
                        previewMode,
                        slug,
                    }),
                    ...generatePreFooterData(
                        globalsData,
                        pageData.PreFooter,
                        true,
                        pageData.Theme.Footer_Media,
                    ),
                    contentType: collection.slug,
                    globalsData,
                    legacyGrid: false,
                    pageData,
                    renderContactSales: slug === "e",
                    renderDemo: slug !== "f",
                    renderNewNavigationBar: true,
                    renderNotifications: true,
                    renderPostFooter: true,
                },
            };
        }
        case "api::page-product.page-product": {
            const pageData = await getProductSlugPage(slug, previewMode);
            return {
                props: {
                    ...generatePageLevelProps({
                        path: `/${slug}`,
                        previewMode,
                        slug,
                    }),
                    ...generatePreFooterData(
                        globalsData,
                        pageData.PreFooter,
                        true,
                        pageData.Theme.Footer_Media,
                    ),
                    contentType: collection.slug,
                    globalsData,
                    legacyGrid: false,
                    pageData,
                    renderDemo: false,
                    renderNewNavigationBar: true,
                    renderNotifications: false,
                    renderPostFooter: true,
                },
            };
        }
    }

    const pageData = await getSlugPageById(slug, previewMode);

    if (!pageData) {
        return { notFound: true };
    }

    // Get form data if Form_Id is defined
    let formData = null;
    if (_.has(pageData, "Form_Id")) {
        // Note: We need to cast since slug.tsx can be many types
        formData = await getHubSpotForm(pageData.Form_Id as string);
    }

    return {
        props: {
            ...generatePageLevelProps({ path: `/${slug}`, previewMode, slug }),
            ...generateLegacyPreFooterData(
                globalsData,
                _.has(pageData, "Pre_Footer")
                    ? // Typecasting is necessary since there are multiple content types here
                      (pageData.Pre_Footer as StrapiLegacyPreFooter)
                    : undefined,
                pageData.contentType !== "pages-general-text",
                getProductFeatureBackgroundMedia(pageData),
            ),
            contentType: collection.slug,
            formData,
            globalsData,
            pageData,
            renderMinimalNav:
                _.has(pageData, "Minimal_Nav") &&
                (pageData.Minimal_Nav as boolean),
        },
    };
};

export const getStaticPaths: GetStaticPaths = async () => {
    const slugs = await getDynamicPageSlugs();

    return {
        fallback: false,
        paths: slugs.map((slug) => ({ params: { slug } })),
    };
};

type PageProps = InferGetStaticPropsType<typeof getStaticProps>;

const Page: NextPage<PageProps> = (props) => {
    /**
     * Elements
     */
    switch (props.contentType) {
        case "exp-homes":
            return (
                <HomePageTemplate
                    {...props.pageData}
                    renderDemo={props.renderDemo}
                />
            );
        case "pages-contact-form":
            return (
                <ContactFormPageTemplate
                    {...(props.pageData as StrapiContactFormPage)}
                    formData={props.formData}
                />
            );
        case "pages-product":
            // Currently, product pages use the same template as the hoomepage
            return (
                <HomePageTemplate
                    {...props.pageData}
                    renderDemo={props.renderDemo}
                />
            );
        case "pages-product-feature":
            return (
                <LegacyProductPageTemplate
                    {...(props.pageData as StrapiProductFeaturePage)}
                />
            );
        default:
            return (
                <GeneralTextPageTemplate
                    {...(props.pageData as StrapiGeneralTextPage)}
                />
            );
    }
};

export default Page;
