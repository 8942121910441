/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiProductFeaturePage } from "@/types/strapi";

import { spacingSets } from "@/tokens/configs/spacing_config";

import { Divider } from "@/ui/atoms/divider";
import { FaqSection } from "@/ui/organisms/faq_section";
import { LegacyBenefitsGrid } from "@/ui/organisms/legacy_benefits_grid";
import { LegacyBenefitsRow } from "@/ui/organisms/legacy_benefits_row";
import { LegacyBigTileGrid } from "@/ui/organisms/legacy_big_tile_grid";
import { LegacyCarousel } from "@/ui/organisms/legacy_carousel";
import { LegacyFeature } from "@/ui/organisms/legacy_feature";
import { LegacyTestimonial } from "@/ui/organisms/legacy_testimonial";
import { LegacyTweetGrid } from "@/ui/organisms/legacy_tweet_grid";
import { ProductHero } from "@/ui/organisms/product_hero";
import TextFeature from "@/ui/organisms/text_feature";

import { buildStylesByBreakpoint } from "@/util/style_util";

export const LegacyProductPageTemplate: FunctionComponent<
    StrapiProductFeaturePage
> = (props) => {
    /**
     * Styles
     */
    const containerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
        },
        buildStylesByBreakpoint("rowGap", spacingSets.DefaultSectionMargin),
    );

    /**
     * Elements
     */
    const renderPageBody = () => {
        const pageBodyElements = props.Page_Body.map(
            (_pageBodyItem, _index) => {
                const _key = `product-page::body-item::${_pageBodyItem.__component}-${_index}`;

                switch (_pageBodyItem.__component) {
                    case "sections.section-legacy-feature":
                        if (
                            _pageBodyItem.Features &&
                            _pageBodyItem.Features.length > 0
                        ) {
                            return (
                                <LegacyFeature {..._pageBodyItem} key={_key} />
                            );
                        }

                        return (
                            <TextFeature
                                {..._pageBodyItem}
                                key={_key}
                                marginTop="None"
                            />
                        );
                    case "sections.section-testimonial":
                        return (
                            <LegacyTestimonial {..._pageBodyItem} key={_key} />
                        );
                    case "sections.section-fa-qs":
                        return <FaqSection {..._pageBodyItem} key={_key} />;
                    case "sections.section-legacy-benefits":
                        return (
                            <LegacyBenefitsGrid
                                {..._pageBodyItem}
                                items={_pageBodyItem.Benefits}
                                key={_key}
                            />
                        );
                    case "sections.section-legacy-tweet-grid":
                        return (
                            <LegacyTweetGrid {..._pageBodyItem} key={_key} />
                        );
                    case "sections.section-legacy-big-tile-grid":
                        return (
                            <LegacyBigTileGrid {..._pageBodyItem} key={_key} />
                        );
                    default:
                        return <LegacyCarousel {..._pageBodyItem} key={_key} />;
                }
            },
        );

        return pageBodyElements.reduce((acc, curr, index) => {
            if (index < pageBodyElements.length - 1) {
                return [
                    ...acc,
                    curr,
                    <Divider key={`product-page::divider::${index}`} />,
                ];
            } else {
                return [...acc, curr];
            }
        }, [] as JSX.Element[]);
    };

    return (
        <div css={containerStyles}>
            <ProductHero
                {...props.Hero}
                onlyRenderDownloadCta={props.Minimal_Nav}
            />

            {props.Overview_Items && props.Overview_Items.length > 0 && (
                <>
                    <LegacyBenefitsRow items={props.Overview_Items} />

                    <Divider />
                </>
            )}

            {renderPageBody()}
        </div>
    );
};
